<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel expand>
        <v-expansion-panel-header>
          <div class="v-card__title">Client Info</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="first_form" lazy-validation class="v-form multi-col-validation">
            <div class="row">
              <div class="col-md-6 col-12">
                <v-text-field
                  required
                  v-model="client_name"
                  :label="'Client Name'"
                  clear-icon="shrink"
                  :rules="emptyRule"
                  outlined
                  type="name"
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                  v-model="phone_number"
                  :label="'Phone Number'"
                  clear-icon="shrink"
                  :rules="emptyRule"
                  outlined
                  type="number"
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                  v-model="email"
                  :label="'Email'"
                  clear-icon="shrink"
                  outlined
                  type="email"
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-6 col-12">
                <v-text-field
                  v-model="address"
                  :label="'Address Note'"
                  clear-icon="shrink"
                  outlined
                  type="email"
                  dense
                ></v-text-field>
              </div>
              <template>
                <l-map style="height: 500px; width: 100%" :zoom="zoom" :center="center" @click="mapclick">
                  <l-polyline
                    v-if="get_magazine != null"
                    :lat-lngs="[
                      [
                        { lat: get_magazine.lat, lng: get_magazine.lng },
                        { lat: markerLatLng[0], lng: markerLatLng[1] },
                      ],
                    ]"
                    @click="alert(item)"
                  />
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-marker :lat-lng="markerLatLng">
                    <l-tooltip :options="{ permanent: true }">
                      {{ client_name }}
                    </l-tooltip>
                  </l-marker>
                  <l-marker v-if="get_magazine != null" :lat-lng="[get_magazine.lat, get_magazine.lng]">
                    <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" icon-url="apartment.png" />

                    <l-tooltip :options="{ permanent: true }">
                      {{ get_magazine.name }}
                    </l-tooltip>
                  </l-marker>
                </l-map>
              </template>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="v-card__title">Order Info</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card style="padding: 10px">
            <div class="v-card__text">
              <v-form ref="second_form" lazy-validation class="v-form multi-col-validation">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="text-center text-uppercase text--primary bold">Date</label>

                    <div
                      class="
                        v-input v-input--hide-details v-input--dense
                        theme--light
                        v-text-field
                        v-text-field--is-booted
                        v-text-field--enclosed
                        v-text-field--outlined
                        v-text-field--placeholder
                      "
                    >
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <fieldset aria-hidden="true">
                            <legend style="width: 0px"><span class="notranslate">​</span></legend>
                          </fieldset>

                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                dense
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="text-center text-uppercase text--primary bold">Magazine</label>

                    <v-select
                      :rules="[v => !!v || 'Item is required!']"
                      outlined
                      dense
                      @input="name => magazine_selected(name)"
                      :items="stocks.map(a => a.magazine_name)"
                    ></v-select>
                  </div>
                  <div class="col-md-6 col-12">
                    <v-checkbox v-model="deliver_by_driver" :label="`Deliver By Driver`" outlined></v-checkbox>
                  </div>
                  <div class="col-md-6 col-12">
                    <v-text-field
                      v-model="delivery_fee"
                      :label="'Delivery Fee'"
                      clear-icon="shrink"
                      :suffix="currency"
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </div>
                  <div></div>

                  <div></div>
                </div>
              </v-form>
            </div>

            <v-card class="mx-auto mt-10" outlined>
              <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
                <div data-v-6fcca708="" class="me-2 mb-2">
                  <div data-v-6fcca708="" class="v-card__title pt-0 px-0">Received Stocks</div>
                </div>
                <button
                  data-v-6fcca708=""
                  v-on:click="
                    selected_stocks.push({
                      id: Math.random() * 10000,
                      product_id: stocks[0].product_id,
                      amount: 0,
                      quantity_price: 0,
                      tax: selected_stocks.length == 0 ? 20 : selected_stocks.slice(-1).pop().tax,
                      discount: 0,
                    })
                  "
                  type="button"
                  class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
                >
                  <span class="v-btn__content">add</span>
                </button>
              </div>
              <v-form ref="third_form" lazy-validation>
                <v-simple-table height="auto" max-height="500" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase">Product</th>

                        <th class="text-center text-uppercase">Quantity Price</th>
                        <th class="text-center text-uppercase">
                          Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                        <th class="text-center text-uppercase">Discount %</th>
                        <th class="text-center text-uppercase">Tax %</th>
                        <th class="text-center text-uppercase">Total</th>
                        <th class="text-center text-uppercase">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="stock in selected_stocks" :key="stock.id">
                        <td class="text-center" style="padding: 10px">
                          <v-select
                            :rules="[v => !!v || 'Item is required']"
                            dense
                            class="shrink"
                            outlined
                            @input="id => product_selected(stock, id)"
                            :items="getProducts"
                          ></v-select>
                        </td>

                        <td class="text-center" style="padding: 10px">
                          <v-text-field
                            v-model="stock['quantity_price']"
                            :label="'Price of one ' + stock.measurement_name"
                            clear-icon="shrink"
                            :suffix="currency"
                            outlined
                            type="number"
                            dense
                          ></v-text-field>
                        </td>
                        <td class="text-center" style="padding: 10px">
                          <v-text-field
                            :label="'Amount' + '(available: ' + stock['product_amount'] + ')'"
                            :key="key"
                            v-model="stock['amount']"
                            :suffix="stock.measurement_name"
                            outlined
                            dense
                            :rules="[
                              v => !!v || 'Item is required!',
                              v =>
                                v <= parseInt(stock['product_amount']) ||
                                'cant be higher than ' + stock['product_amount'] + '',
                            ]"
                            :min="1"
                            :max="stocks.find(x => x.product_id === stock.product_id).amount"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-center" style="padding: 10px">
                          <v-text-field
                            label="Discount"
                            v-model="stock['discount']"
                            suffix="%"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-center" style="padding: 10px">
                          <v-text-field
                            label="Tax"
                            v-model="stock['tax']"
                            suffix="%"
                            outlined
                            dense
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <label v-if="stock['amount'] == 0 || stock['quantity_price'] == 0">0 {{ currency }}</label>
                          <label v-else>{{ calculateTotal(stock) }} {{ currency }}</label>
                        </td>
                        <td class="text-center">
                          <div
                            v-on:click="selected_stocks = selected_stocks.filter(item => item !== stock)"
                            class="v-avatar elevation-1 rounded error"
                            style="height: 30px; min-width: 30px; width: 30px"
                          >
                            <span
                              aria-hidden="true"
                              class="v-icon notranslate theme--dark white--text"
                              style="font-size: 25px; height: 25px; width: 25px"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                role="img"
                                aria-hidden="true"
                                class="v-icon__svg"
                                style="font-size: 25px; height: 25px; width: 25px"
                              >
                                <path :d="icons.mdiCloseCircleOutline"></path></svg
                            ></span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-form>
              <span class="text-decoration-none" style="font-weight: 600"
                >Sub Total: {{ getSubTotal }} {{ currency }}</span
              >
              <br />
              <span class="text-decoration-none" style="font-weight: 600"
                >Total Discount: {{ getDiscount }} {{ currency }}</span
              >
              <br />
              <span class="text-decoration-none" style="font-weight: 600">Total Tax: {{ getTax }} {{ currency }}</span>
              <br />
              <span class="text-decoration-none" style="font-weight: 600"
                >Delivery Fee: {{ get_delivery_fee }} {{ currency }}</span
              >
              <br />
              <v-divider></v-divider>
              <div class="d-flex justify-space-between flex-wrap">
                <span class="text-decoration-none" style="font-weight: 600; color: black"
                  >Grand Total: {{ getGrandTotal }} {{ currency }}</span
                >
              </div>
            </v-card>
          </v-card>
          <v-btn color="success" style="margin: 10px" v-on:click="submit"> Submit </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>



<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mdiPlus, mdiCloseCircleOutline } from '@mdi/js'
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import * as moment from 'moment'
import { getStocks } from '@/ApiManager'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  props: ['id'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  methods: {
    mapclick(data) {
      this.markerLatLng = [data.latlng['lat'], data.latlng['lng']]
    },

    turnStockToString(stock) {
      return `${stock.id}${stock.amount}${stock.product.id}${stock.quantity_price}${stock.tax}${stock.discount}`
    },
    calculateTotal(stock) {
      var price = 0
      //get total price
      price = stock.amount * stock.quantity_price
      //calculate discount
      price = price - (price * stock.discount) / 100
      //calculate tax
      price = price + (price * stock.tax) / 100
      return price
    },
    product_selected(stock, name) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].product_name == name) {
          stock['product_id'] = this.stocks[i].product_id
          stock['measurement_name'] = this.stocks[i].measurement_name
          stock['product_amount'] = this.stocks[i].product_amount
          stock['amount'] = 1

          stock['quantity_price'] = this.stocks[i].sell_price
          this.key += 1
        }
      }
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].magazine_name == magazine_name) {
          this.selected_magazine = {
            name: this.stocks[i].magazine_name,
            id: this.stocks[i].magazine_id,
            lat: this.stocks[i].magazine_lat,
            lng: this.stocks[i].magazine_lng,
          }
        }
      }
    },
    submit: function (_) {
      if (this.$refs['first_form'].validate() == false || this.$refs['second_form'].validate() == false) {
        return
      }
      var order_status = null
      if (this.deliver_by_driver == false) {
        order_status = 'completed'
        this.driver_id = null
      } else {
        order_status = 'waiting_for_a_driver'
      }
      var _stocks = []
      for (var i = 0; i < this.selected_stocks.length; i++) {
        _stocks.push([
          parseInt(this.selected_stocks[i]['product_id']),
          parseInt(this.selected_stocks[i]['amount']),
          parseInt(this.selected_stocks[i]['quantity_price']),
          parseInt(this.selected_stocks[i]['tax']),
          parseInt(this.selected_stocks[i]['discount']),
        ])
      }
      console.log(_stocks)
      axios({
        //im pretty sure this is the old api (the uncommneted code)
        // TODO:-
        //axios({ url: `https://invictsoft.com/api/edit_transfers?id=${this.id}&TODO_OTHER_FIELDS&id_token=${this.$cookies.get('login')}`})
        //REDIRECT
        //     this.$router.push({ name: 'transfers' })
        url: `https://invictsoft.com/toy.invictsoft.com/ajax.php?request=insert_new_transfer&client_name=${
          this.client_name
        }&order_status=${order_status}&magazine_id=${this.selected_magazine.id}&phone_number=${
          this.phone_number
        }&email=${this.email}&address_note=${this.address}&lat=${this.markerLatLng[0]}&lng=${
          this.markerLatLng[1]
        }&date=${this.date}&driver_id=${this.driver_id}&delivery_fee=${this.delivery_fee}&stocks=${JSON.stringify(
          _stocks,
        )}`,
      }).then(response => {
        console.log(response)
        this.$router.push({ name: 'transfers' })
      })
    },
  },
  computed: {
    getProducts() {
      if (this.selected_magazine == null) {
        return []
      }
      return this.stocks.filter(item => item.magazine_id == this.selected_magazine.id).map(a => a.product_name)
    },
    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    getSubTotal() {
      var price = 0
      for (var i = 0; i < this.selected_stocks.length; i++) {
        price += this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount
      }
      return price
    },
    getDiscount() {
      var price = 0
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var cost = this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount
        price += (cost * this.selected_stocks[i].discount) / 100
      }
      return price
    },
    getTax() {
      var price = 0
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var cost = this.selected_stocks[i].quantity_price * this.selected_stocks[i].amount
        cost -= (cost * this.selected_stocks[i].discount) / 100
        price += (cost * this.selected_stocks[i].tax) / 100
      }
      return price
    },
    get_magazine() {
      return this.selected_magazine
    },
    get_delivery_fee() {
      return this.delivery_fee
    },
    getGrandTotal() {
      var price = 0
      for (var i = 0; i < this.selected_stocks.length; i++) {
        var stock = this.selected_stocks[i]
        //get total price
        var cost = stock.amount * stock.quantity_price
        console.log(stock.discount, stock.tax)
        //calculate discount
        if (stock.discount != 0) {
          cost = cost - (cost * stock.discount) / 100
        }
        //calculate tax
        if (stock.tax != 0) {
          cost = cost + (cost * stock.tax) / 100
        }
        price += cost
      }
      return parseInt(price) + parseInt(this.delivery_fee)
    },
  },
  async created() {
    getStocks().then(response => {
      for (var i = 0; i < response.length; i++) {
        var product = {
          id: response[i]['id'],
          product_name: response[i]['product_name'],
          magazine_name: response[i]['magazine_name'],
          product_id: response[i]['product_id'],
          magazine_id: response[i]['magazine_id'],
          magazine_lat: response[i]['magazine_lat'],
          magazine_lng: response[i]['magazine_lng'],
          measurement_name: response[i]['measurement_name'],
          sell_price: response[i]['sell_price'],

          product_amount: response[i]['amount'],
        }
        this.stocks.push(product)
      }
      console.log(this.stocks)
    })
    getCurrency().then(response => {
      this.currency = response['currency']
    })
    var coordinates = await this.$getLocation()
    this.center = [coordinates['lat'], coordinates['lng']]
    this.markerLatLng = [coordinates['lat'], coordinates['lng']]
    this.leaflet_key += 1
  },
  data() {
    return {
      delivery_fee: 0,
      deliver_by_driver: true,
      panel: 0,
      emptyRule: [v => !!v || "Can't be empty!"],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
      currency: '',
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu2: false,
      stocks: [],
      selected_stocks: [],

      key: 0,
      selected_magazine: null,
      client_name: '',
      email: '',
      phone_number: '',
      address: '',
      magazines: [],
    }
  },
  setup() {
    const product_name = ref('')
    const leaflet_key = ref('')
    const inventory_address = ref('')
    const sell_price = ref('')

    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
    }
  },
}
</script>
<style scoped>
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');

.bold {
  color: black;
  font-family: sans-serif;
  font-weight: 700;
}
.component-container {
  display: table;
  @media (pointer: none), (pointer: coarse) {
    width: calc(100% - (50%));
  }
}
.component-row {
  display: table-row;
}
.component-row div {
  position: relative;
  display: table-cell;
}
.component-row div:nth-child(2) {
  left: 20px;
}
.component-row div:nth-child(3) {
  left: 40px;
}
</style>